@import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css');
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-balham.css';

.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
  width: 100%;
}

h1 {
    color: #369;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 250%;
  }
  h2, h3 {
    color: #444;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
  }
  body {
    margin: 2em;
  }
  body, input[type="text"], button {
    color: #333;
    font-family: Cambria, Georgia, serif;
  }
  button {
    background-color: #eee;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: black;
    font-size: 1.2rem;
    padding: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  button:hover {
    background-color: black;
    color: white;
  }
  button:disabled {
    background-color: #eee;
    color: #aaa;
    cursor: auto;
  }

  /* everywhere else */
  * {
    font-family: Arial, Helvetica, sans-serif;
  }
.card-body {
    padding: 2px 20px;
    h6 {
        border-bottom: 1px solid #eceeef;
        padding-bottom: 2px;
        font-weight: 600px;
    }
}
.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-full-col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-grow {
  flex-grow: 1;
}
.paneMinHeight {
  min-height: 150px;
}
.card-body {
  padding: 2px 20px;
  h6 {
      border-bottom: 1px solid #eceeef;
      padding-bottom: 2px;
      font-weight: 600px;
  }
}
.header_one {
  background-color: #005ea2 !important;
  color: white !important;
  font-weight: bold !important;
}
.table-cell {
  font-size: 14px;             
  color: black;                
  background-color: #f0f8ff;   
  padding: 2px;               
  border: 1px solid #ccc;      
  border-radius: 4px;          
  text-align: center;          
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); 
}
.table-cell select {
  width: 100%;                 
  padding: 5px;                
  border: none;                
  background: transparent;     
  font-size: inherit;          
  outline: none          
}
.table-cell select option:first-child {
  color: gray; 
}
p {
  line-height: 1.5;
}